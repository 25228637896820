<template>
    <form-field>
        <div
            class="form-checkbox"
            :class="{ 'form-checkbox--nolabel': !props.label }"
        >
            <input
                :id="props.id"
                v-model="modelValue"
                type="checkbox"
                :value="props.value"
                :disabled="props.disabled"
                @change="emits('change', $event)"
                @click="emits('inputClick', $event)"
            />

            <label
                v-if="props.label"
                :for="props.id"
                class="form-label !mb-0"
            >{{ props.label }}</label>

            <label
                v-else
                :for="props.id"
            />
        </div>

        <form-field-errors :errors="errors" />
    </form-field>
</template>

<script setup lang="ts">
    export interface Props {
        id: string;
        name?: string;
        label?: string;
        value?: any;
        required?: boolean;
        disabled?: boolean;
        errors?: string[] | string;
    }

    const props = withDefaults(defineProps<Props>(), {
        name: undefined,
        label: undefined,
        value: undefined,
        required: false,
        disabled: false,
        errors: undefined,
    });

    const emits = defineEmits<{
        change: [event: Event];
        inputClick: [event: MouseEvent];
    }>();

    const modelValue = defineModel<boolean | (number | string)[]>();
</script>

<style lang="postcss">
    .form-checkbox {
        @apply relative;

        label {
            @apply flex items-start cursor-pointer;

            &::before {
                @apply block w-5 h-5 flex-shrink-0 mr-2;
                @apply border border-gray-500 bg-transparent rounded;

                content: '';
            }
        }

        input[type="checkbox"] {
            @apply sr-only;

            &:checked + label::after {
                @apply block absolute w-5 h-5 left-0 top-0;
                @apply bg-center bg-no-repeat;

                content: '';
                background-image: svg-load('img/icons/check.svg', fill: theme('colors.green.600'));
                background-size: theme('spacing.3') theme('spacing.3');
            }

            &:indeterminate + label::after {
                @apply block absolute w-3 h-0.5 left-1;
                @apply bg-green-600;

                top: 9px;
                content: '';
            }

            &:focus + label::before {
                @apply ring-2 ring-offset-2 ring-green-600 dark:ring-offset-gray-900;
            }

            &:disabled + label {
                @apply opacity-50 cursor-not-allowed;
            }
        }

        &--nolabel label {
            &::before {
                @apply mr-0;
            }
        }
    }
</style>
